import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Brandlogo = () => {
  const images = [
    {
      src: "./images/logos/brandlogos/bonito_designs.png",
      alt: "Bonito Designs Logo",
    },
    {
      src: "./images/logos/brandlogos/louis_philippe.png",
      alt: "Louis Phillppe Logo",
    },
    { src: "./images/logos/brandlogos/man.png", alt: "Man Logo" },
    {
      src: "./images/logos/brandlogos/ginger_hotel_logo.png",
      alt: "Ginger Hotel Logo",
    },
    {
      src: "./images/logos/brandlogos/peter_england.png",
      alt: "Peter England Logo",
    },

    {
      src: "./images/logos/brandlogos/tata_motors.png",
      alt: "Tata Motors Logo",
    },
    {
      src: "./images/logos/brandlogos/caratlane_logo.png",
      alt: "Caratlane Logo",
    },
  ];

  const options = {
    loop: true,
    margin: 10,
    dots: false,
    autoplay: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  return (
    <div
      className="layer-content brandlogo"
      style={{ backgroundColor: "#F0EBE3" }}
    >
      <OwlCarousel className="owl-theme" {...options}>
        {images.map((image, index) => (
          <div key={index} className="item">
            <div className="featured-imagebox featured-imagebox-service style2">
              <div className="featured-thumbnail d-flex justify-content-center px-3">
                <img
                  style={{ width: "150px" }}
                  src={image.src}
                  alt={image.alt}
                />
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Brandlogo;
