import React, { useEffect } from "react";
import { Pagetitle } from "../Components/Pagetitle";
// import { Signagelist } from '../Components/Signagelist';
import { Signagelist2 } from "../Components/Signagelist2";
// import { Servicesmarquee } from '../Components/Servicesmarquee';
// import { Serviceslider } from '../Components/Serviceslider';
import { Servicesinage } from "../Components/Servicesinage";

export const Services = () => {
  useEffect(() => {
    document.title = "Digital Signage | Services";
  });

  return (
    <>
      <Pagetitle {...{ name: "Products" }} />

      <div className="site-main">
        <section
          className="prt-row about-section clearfix service-cot"
          style={{ paddingBottom: "18px" }}
        >
          <div className="container">
            <div className="row text-center">
              {/* <div className="col-lg-7 col-md-12 col-sm-12"> */}
              <div className="section-title">
                <div className="title-header ">
                  <h2 className="title " style={{ fontSize: "30px" }}>
                    Constantly Innovating with Experts in the Field of Design,
                    Manufacture and Installation.
                  </h2>
                </div>
                {/* </div> */}
              </div>
            </div>

            <Servicesinage />

            {/* <Serviceslider />

            <Servicesmarquee /> */}
          </div>
        </section>

        <Signagelist2 />

        {/* <Signagelist /> */}
      </div>
    </>
  );
};
