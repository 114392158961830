import React, { useEffect } from "react";
import { Pagetitle } from "../Components/Pagetitle";
import { Award } from "../Components/Award";
// import { Ourexpert } from "../Components/Ourexpert";
// import { Companyprogress } from "../Components/Companyprogress";
// import { Testimonial } from "../Components/Testimonial";
// import { Faq } from "../Components/Faq";
import { Faq2 } from "../Components/Faq2";
// import { Aboutcounter } from "../Components/Aboutcounter";
// import { Portfolio } from "../Components/Portfolio";
import { Mission } from "../Components/Mission";
import { Vision } from "../Components/Vision";
import { Values } from "../Components/Values";
import ScrollAnimation from "react-animate-on-scroll";

export const Aboutus = () => {
  useEffect(() => {
    document.title = "Digital Signage | About Us";
  }, []);

  return (
    <>
      <Pagetitle {...{ name: "About Us" }} />

      <div className="site-main">
        <section className="prt-row about-section_1 clearfix">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 mx-auto">
                <div className="">
                  <div className="section-title">
                    <div className="title-header">
                      <h2 className="title text-center">Who we are</h2>
                    </div>
                    <div className="title-desc">
                      <ScrollAnimation animateIn="zoomIn">
                        <p className="text-center">
                          Welcome to Digital Signage Company, your premier
                          partner in revolutionizing visual communication. At
                          Digital Signage Company, we blend cutting-edge
                          technology with creative brilliance to transform
                          spaces into dynamic, engaging environments. Our
                          mission is to help businesses of all sizes captivate
                          their audiences with impactful digital displays that
                          convey messages with clarity and style.
                        </p>
                      </ScrollAnimation>
                      <ScrollAnimation animateIn="zoomIn">
                        <p className="text-center">
                          With years of expertise in the industry, we pride
                          ourselves on delivering customized solutions that
                          cater to the unique needs of each client. Our team of
                          passionate professionals is dedicated to providing
                          top-notch service, from initial consultation and
                          design to installation and ongoing support. We believe
                          that every screen is an opportunity to inspire, inform
                          and innovate.
                        </p>
                      </ScrollAnimation>
                      {/* <ScrollAnimation animateIn="zoomIn">
                        <p className="text-center">
                          Join us in creating a brighter, more connected world –
                          one screen at a time.
                        </p>
                      </ScrollAnimation> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Aboutcounter /> */}
              {/* <div className="col-lg-12 col-md-12">
                <div className="prt-bg prt-col-bgimage-yes col-bg-img-one prt-right-span spacing-4 z-index_1">
                  <div className="prt-col-wrapper-bg-layer prt-bg-layer"></div>
                  <img
                    className="img-fluid prt-equal-height-image w-100"
                    src="./images/banners/col-bgimage-1.jpg"
                    alt="bg-image"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <Award />
        {/* <Portfolio /> */}
        {/* <Companyprogress /> */}
        <Mission />
        <Vision />
        <Values />
        {/* <Testimonial /> */}
        {/* <Ourexpert /> */}
        {/* <Faq /> */}
        <Faq2 />
      </div>
    </>
  );
};
