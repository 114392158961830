import React from "react";
export const Servicesmarquee = ({ data }) => {
  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <a
            className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill mb-15 mt-10 w-80 prt-btn-color-dark mx-auto d-block"
            style={{ width: "170px" }}
            href="/contactus"
          >
            Contact Us
          </a>
          {/* <div className="prt-marquebox-main">
            <div className="prt-marquebox-wrapper">
              <div className="prt-marqueboxes-wrapper prt-marquebox-style3">
                <ul className="icon-list-items marquebox-list">
                  {data.map((text, index) => (
                    <li className="icon-list-item" key={index}>
                      <span className="marque-text">{text.marqueetext}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default Servicesmarquee;
